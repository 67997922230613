import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import { get } from '@/helpers';

/* globals EngageSDK */
export default new Vuex.Store({
	state: {
		sports: [],
		main_nav_items: [],
		footer_nav_items: [],
		chatEnabledRoutes: ['Championship', 'ChampionshipChampInfo'],
		chatEnabledRouteSlugs: ['womens-volleyball'],
	},
	mutations: {
		set_sports(state, payload) {
			state.sports = payload;
		},
		set_main_nav_items(state, payload) {
			state.main_nav_items = payload;
		},
		set_footer_nav_items(state, payload) {
			state.footer_nav_items = payload;
		},
		toggle_chat(state, { visibility }) {
			try {
				setTimeout(() => {
					const engageTabs = document.querySelectorAll('.engage-tab');
					if (visibility) {
						engageTabs.forEach(tab => {
							tab.classList.remove('display-none');
						});
					} else {
						engageTabs.forEach(tab => {
							tab.classList.add('display-none');
						});
					}
				}, 800);
			} catch (e) {
				console.warn(e);
			}
		},
	},
	actions: {
		fetch_sports(store) {
			const request_options = {
				type: 'sports',
			};
			get(
				'https://d230gea5qwdu36.cloudfront.net/services/adaptive_components.ashx',
				request_options,
			).then(response => {
				store.commit('set_sports', response);
			});
		},
		fetch_navigation(store) {
			const request_options = {
				type: 'main-navigation',
			};
			get(
				'https://d230gea5qwdu36.cloudfront.net/services/adaptive_components.ashx',
				request_options,
			).then(response => {
				response.forEach(item => {
					item.is_open = false;
				});

				let main_navigation_data = response.filter(
					item => parseInt(item.id) <= 5,
				);
				let footer_navigation_data = response.filter(
					item => parseInt(item.id) >= 6,
				);

				store.commit('set_main_nav_items', main_navigation_data);
				store.commit('set_footer_nav_items', footer_navigation_data);
			});
		},
		show_chat(store) {
			store.commit('toggle_chat', { visibility: true });
		},
		close_chat(store) {
			store.commit('toggle_chat', { visibility: false });
		},
	},
	getters: {
		get_sport_from_slug: state => slug => {
			return state.sports.find(sport => sport.slug === slug);
		},
		is_chat_enabled_for_this_route: state => (
			routeName,
			routeSlug = '',
		) => {
			if (
				state.chatEnabledRoutes.length === 0 ||
				!state.chatEnabledRoutes
			) {
				return true;
			}

			if (
				routeName &&
				routeSlug &&
				state.chatEnabledRouteSlugs &&
				state.chatEnabledRouteSlugs.length
			) {
				return (
					state.chatEnabledRoutes.indexOf(routeName) >= 0 &&
					state.chatEnabledRouteSlugs.indexOf(routeSlug) >= 0
				);
			} else if (routeName) {
				return state.chatEnabledRoutes.indexOf(routeName) >= 0;
			} else {
				return false;
			}
		},
	},
});

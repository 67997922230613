<template>
	<div
		class="component c-search align-items-center display-flex height-100"
		v-click-outside="close_modal"
	>
		<button
			class="c-search__toggle reset text-on-primary transition"
			:class="toggled ? 'is-open' : undefined"
			@click.prevent="toggle_modal"
			@keyup.enter="toggle_modal"
		>
			<search-icon v-if="!toggled"></search-icon>
			<x-icon v-else></x-icon>
		</button>
		<div
			class="c-search__popout bg-color-white border position-absolute top-100 right-0 transition width-100"
			:class="toggled ? 'is-toggled' : undefined"
		>
			<div
				class="c-search__container display-flex transition"
				:class="
					toggled
						? 'opacity-1 transition-delay-25'
						: 'opacity-0 transition-duration-15'
				"
			>
				<input
					label="Search input"
					placeholder="What are you looking for?"
					ref="search_input"
					type="text"
					class="c-search__input align-self-stretch flex-item-1 font-size-18 bp-1024:font-size-30 header-font padding-y-32 padding-x-16 bp-1024:padding-y-104 bp-1024:padding-x-64 reset text-transform-uppercase text-dark"
					v-model="query"
					@keyup.enter="search"
				/>
				<button
					class="c-search__submit align-self-stretch flex-item-auto padding-y-32 padding-x-16 bp-1024:padding-y-104 bp-1024:padding-x-64 reset transition"
					@click="search"
					@keyup.enter="search"
					:disabled="query === ''"
				>
					<arrow-right-icon
						class="c-search__submit-icon display-inline-block"
					></arrow-right-icon>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { ArrowRightIcon, SearchIcon, XIcon } from 'vue-feather-icons';

export default {
	name: 'Search',

	components: {
		ArrowRightIcon,
		SearchIcon,
		XIcon,
	},

	data: () => ({
		toggled: false,
		query: '',
	}),

	methods: {
		search() {
			if (this.$route.query.q != this.query) {
				this.$router.push({
					path: '/search',
					query: { q: this.query },
				});
				this.close_modal();
				this.query = '';
			}
		},

		open_modal() {
			this.toggled = true;
			this.$refs.search_input.focus();
		},

		close_modal() {
			this.toggled = false;
		},

		toggle_modal() {
			this.toggled = !this.toggled;
			if (this.toggled) {
				this.$refs.search_input.focus();
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.c-search {
	&__toggle {
		border-left: 1px solid #09649b;
		height: 100%;
		width: 66px;

		&:hover,
		&:focus,
		&.is-open {
			background: var(--color-text-on-primary);
			cursor: pointer;
			color: var(--color-primary);
		}
	}

	&__popout {
		pointer-events: none;
		transform: scaleY(0);
		transform-origin: top center;

		&.is-toggled {
			pointer-events: auto;
			transform: scaleY(1);
		}
	}
	&__submit {
		background: var(--color-text-on-primary);
		color: var(--color-primary);
		flex: 0 0 auto;

		&-icon {
			transition: transform 0.25s;
			transform: translateX(0);
		}

		&:hover,
		&:focus {
			background: var(--color-primary);
			color: var(--color-text-on-primary);
			cursor: pointer;

			.c-search__submit-icon {
				transform: translateX(3px);
			}
		}

		&:disabled,
		&[disabled] {
			&:hover,
			&:focus {
				background: var(--color-text-on-primary);
				color: var(--color-primary);
				cursor: not-allowed;

				.c-search__submit-icon {
					transform: translateX(0);
				}
			}
		}
	}
}

@media screen and (min-width: 1024px) {
	.c-search {
		&__toggle {
			border-left: none;
		}
	}
}
</style>

<style lang="scss">
body.has-min-nav {
	.c-search {
		display: none !important;

		&__toggle {
			color: var(--color-primary);
		}
	}
	@media screen and (min-width: 1024px) {
		.c-search {
			display: flex !important;
		}
	}
}
</style>

<template>
	<div
		class="c-navigation__container align-items-center display-flex height-100 bp-1024:padding-r-24"
	>
		<div
			class="c-navigation__logo display-flex align-items-center flex-item-auto margin-r-auto"
		>
			<router-link
				to="/"
				class="display-block padding-x-24 text-decoration-none width-100"
			>
				<img
					class="c-navigation__logo-img display-block"
					src="@/assets/main_logo_large.svg"
					alt="NCAA Tickets Logo"
				/>
			</router-link>
		</div>
		<div class="flex-item-1 height-100" v-click-outside="close_dropdowns">
			<div
				class="c-navigation-desktop flex-item-1 height-100 display-none bp-1024:display-block"
			>
				<ul
					class="c-navigation__level-1 reset-list flex-item-1 height-100 display-flex align-items-center justify-content-flex-start"
					style="margin-left: 44px"
				>
				<!-- The above `margin-left: 44px;` results in 100px of space between the NCAA logo and the first nav item. -->
					<li
						class="c-navigation__item--level-1 height-100 transition padding-x-12 bp-1280:padding-x-32"
						:aria-haspopup="
							item.items && item.items.length ? 'true' : 'false'
						"
						:class="[
							item.items && item.items.length
								? 'c-navigation__parent'
								: undefined,
							item.is_open ? 'is-open' : undefined,
						]"
						v-for="item in data"
						:key="item.id"
					>
						<a
							class="c-navigation__url c-navigation__url--level-1 font-weight-400 font-size-16  height-100 position-relative utility-flex-center text-transform-uppercase text-decoration-none text-on-primary header-font"
							:aria-label="item.title"
							:aria-haspopup="
								item.items && item.items.length
									? 'true'
									: 'false'
							"
							:aria-expanded="item.is_open ? 'true' : 'false'"
							:title="item.title"
							href="javascript:void(0)"
							@click="toggle_menu_item(item)"
							@keyup.enter="toggle_menu_item(item)"
							v-if="item.items.length"
						>
							<span>{{ item.title }}</span>
							<chevron-down-icon
								class="margin-l-6 transition"
								size="1x"
							/>
						</a>
						<navigation-link
							_class="c-navigation__url c-navigation__url--level-1 font-weight-400 font-size-16  header-font height-100 position-relative utility-flex-center text-transform-uppercase text-decoration-none text-on-primary transition"
							:_aria-label="item.title"
							:_target="
								item.open_in_new_window ? '_blank' : '_self'
							"
							:_title="item.title"
							:url="item.url"
							_tab-index="0"
							_external-icon
							v-if="!item.items.length"
						>
							<span>{{ item.title }}</span>
							<chevron-right-icon
								class="margin-l-6 transition"
								size="1x"
							/>
						</navigation-link>
						<div
							class="c-navigation__level-2 position-absolute top-100 left-0 width-100"
							:class="item.is_open ? 'transition' : undefined"
							v-if="item.items.length"
						>
							<div
								class="c-navigation__level-2-inner display-flex flex-wrap-wrap bg-color-white overflow-y-auto padding-y-64 padding-x-56"
							>
								<div
									v-if="
										item.items[0].class_name ===
											'full-width-description'
									"
									class="border-b text-muted text-align-center padding-x-12 padding-b-12 margin-t-n12 margin-b-12 margin-x-12 flex-basis-100 flex-grow-1 flex-shrink-1"
								>
									<span
										class="display-inline-block line-height-175"
										v-html="item.items[0].title"
										style="max-width:800px"
									></span>
								</div>
								<ul
									class="c-navigation__column reset-list padding-x-12"
									v-for="column in item.columns"
									:key="column.column"
								>
									<li
										class="c-navigation__item--level-2"
										v-for="_item in column.items"
										:key="_item.id"
										v-show="
											_item.class_name !==
												'full-width-description'
										"
										:class="[
											_item.separator &&
											_item.class_name !==
												'full-width-description'
												? 'c-navigation__separator border-b display-flex align-items-center font-size-16 header-font padding-b-12 text-transform-uppercase'
												: 'border-b hover:bg-color-primary transition',
											_item.items && _item.items.length
												? 'display-flex align-items-center'
												: undefined,
											_item.class_name,
										]"
									>
										<navigation-link
											_class="c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-12 text-black text-decoration-none hover:text-decoration-underline"
											v-if="
												!_item.separator &&
													_item.url != ''
											"
											:_aria-label="_item.title"
											:url="_item.url ? _item.url : ''"
											:_tab-index="
												item.is_open ? '0' : undefined
											"
											:_target="
												_item.open_in_new_window
													? '_blank'
													: '_self'
											"
											:_title="_item.title"
											_external-icon
											>{{ _item.title }}</navigation-link
										>
										<span
											class="c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-12 text-black text-decoration-none"
											v-if="
												!_item.separator &&
													_item.url === ''
											"
											>{{ _item.title }}</span
										>
										<span
											v-if="_item.separator"
											class="text-brand"
											>{{ _item.title }}</span
										>
										<ul
											class="c-navigation__level-3 reset-list display-flex align-items-center margin-l-auto padding-r-6"
											v-if="
												_item.items &&
													_item.items.length
											"
										>
											<li
												class="c-navigation__item--level-3"
												v-for="(item,
												index) in _item.items"
												:key="index"
											>
												<navigation-link
													_class="c-navigation__url c-navigation__url--level-3 display-block margin-y-12 margin-x-6 text-align-center text-decoration-none text-brand transition"
													v-if="!item.separator"
													:_aria-label="item.title"
													:url="
														item.url ? item.url : ''
													"
													:_tab-index="
														item.is_open
															? '0'
															: undefined
													"
													:_target="
														item.open_in_new_window
															? '_blank'
															: '_self'
													"
													:_title="item.title"
													_external-icon
													>{{
														get_gender(item.title)
													}}</navigation-link
												>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</div>
					</li>
				</ul>
			</div>
			<div class="c-navigation-mobile bp-1024:display-none height-100">
				<div
					class="c-navigation__offcanvas bg-color-primary position-absolute top-100 right-0 width-100"
					:class="mobile_menu_open ? 'is-open' : undefined"
				>
					<ul class="c-navigation__level-1 reset-list padding-b-72">
						<li
							class="c-navigation__item--level-1"
							v-for="item in data"
							:key="item.id"
							:class="[
								item.items && item.items.length
									? 'c-navigation__parent'
									: undefined,
								item.is_open ? 'is-open' : undefined,
							]"
						>
							<a
								class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16  font-weight-500 justify-content-space-between header-font padding-24 text-on-primary text-decoration-none text-transform-uppercase transition"
								tabindex="0"
								:aria-label="item.title"
								:aria-haspopup="
									item.items && item.items.length
										? 'true'
										: 'false'
								"
								:aria-expanded="item.is_open ? 'true' : 'false'"
								:title="item.title"
								href="javascript:void(0)"
								@click.prevent="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								@keyup.enter="
									item.items && item.items.length
										? toggle_menu_item(item)
										: undefined
								"
								v-if="item.items.length"
							>
								<span>{{ item.title }}</span>
								<chevron-down-icon
									class="margin-l-6 transition"
									size="1x"
								/>
							</a>
							<navigation-link
								_class="c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16  font-weight-500 header-font justify-content-space-between padding-24 text-on-primary text-decoration-none text-transform-uppercase transition"
								:_aria-label="item.title"
								:_target="
									item.open_in_new_window ? '_blank' : '_self'
								"
								:_title="item.title"
								:url="item.url ? item.url : ''"
								_tabIndex="0"
								v-if="!item.items.length"
								_external-icon
							>
								{{ item.title }}
								<chevron-right-icon
									class="transition"
									size="1x"
								></chevron-right-icon>
							</navigation-link>
							<div
								class="c-navigation__level-2"
								v-if="item.items.length"
								:class="item.is_open ? 'is-open' : undefined"
							>
								<div
									v-if="
										item.items[0].class_name ===
											'full-width-description'
									"
									class="border-b bg-color-surface text-muted text-align-center padding-24"
									v-html="item.items[0].title"
								></div>
								<ul class="reset-list bg-color-surface-alt">
									<li
										class="c-navigation__item--level-2 border-b"
										v-for="_item in item.items"
										v-show="
											_item.class_name !==
												'full-width-description'
										"
										:key="_item.id"
										:class="[
											_item.separator &&
											_item.class_name !==
												'full-width-description'
												? 'c-navigation__separator font-size-16  font-weight-700 header-font'
												: 'transition hover:bg-color-primary',
											_item.items && _item.items.length
												? 'display-flex align-items-center'
												: undefined,
										]"
									>
										<navigation-link
											_class="c-navigation__url c-navigation__url--level-2 display-block font-size-18 padding-y-16 padding-x-32 text-black text-decoration-none transition"
											v-if="
												!_item.separator &&
													_item.url != ''
											"
											:url="_item.url ? _item.url : ''"
											:tabindex="
												item.is_open ? '0' : undefined
											"
											_external-icon
											>{{ _item.title }}</navigation-link
										>
										<span
											class="display-block font-weight-700 padding-y-16 padding-x-32 text-brand"
											v-if="_item.separator"
											>{{ _item.title }}</span
										>
										<span
											class="c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-y-16 padding-x-32 text-black text-decoration-none"
											v-if="
												!_item.separator &&
													_item.url === ''
											"
											>{{ _item.title }}</span
										>
										<ul
											class="c-navigation__level-3 reset-list display-flex align-items-center margin-l-auto padding-r-24"
											v-if="
												_item.items &&
													_item.items.length
											"
										>
											<li
												class="c-navigation__item--level-3"
												v-for="(item,
												index) in _item.items"
												:key="index"
											>
												<navigation-link
													_class="c-navigation__url c-navigation__url--level-3 display-block margin-y-16 margin-x-6 text-align-center text-decoration-none text-brand transition"
													v-if="!item.separator"
													:_aria-label="item.title"
													:url="
														item.url ? item.url : ''
													"
													:_tab-index="
														item.is_open
															? '0'
															: undefined
													"
													:_target="
														item.open_in_new_window
															? '_blank'
															: '_self'
													"
													:_title="item.title"
													_external-icon
													>{{
														get_gender(item.title)
													}}</navigation-link
												>
											</li>
										</ul>
									</li>
								</ul>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<search />
		<router-link
			class="c-navigation__tickets align-items-center display-flex justify-content-center text-on-primary text-transform-uppercase text-decoration-none transition transition-timing-function-ease transition-duration-25 bp-1024:padding-x-12 bp-1024:margin-l-24"
			to="/championships"
		>
			<span
				class="display-none bp-1024:display-block  font-size-16 header-font"
				>Find Tickets</span
			>
			<!-- <svg
				class="align-self-center bp-1024:display-none"
				width="21"
				height="21"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g clip-path="url(#clip0)">
					<path
						d="M14.106 6.443l.707-.707-.707.707zm3.713 0l.707-.707a1 1 0 00-1.415 0l.707.707zm-3.713-3.712l.707.707a1 1 0 000-1.414l-.707.707zM12.25.875l.707-.707a1 1 0 00-1.414 0l.707.707zM1.113 12.012l-.707-.707a1 1 0 000 1.414l.707-.707zm1.856 1.856l-.707.707a1 1 0 001.414 0l-.707-.707zm3.713 3.712l-.708-.707a1 1 0 000 1.415l.708-.708zm1.856 1.857l-.707.707a1 1 0 001.414 0l-.707-.707zM19.675 8.3l.707.707a1 1 0 000-1.414l-.707.707zm-6.276-1.15a3.625 3.625 0 005.127 0L17.11 5.737a1.625 1.625 0 01-2.298 0L13.4 7.151zm0-5.126a3.625 3.625 0 000 5.127l1.414-1.415a1.625 1.625 0 010-2.298L13.4 2.024zm-1.856-.442l1.856 1.856 1.414-1.414L12.957.168l-1.414 1.414zM1.82 12.72L12.957 1.582 11.543.168.406 11.305l1.414 1.414zm1.856.442L1.82 11.305.406 12.719l1.856 1.856 1.414-1.414zm0 1.414a1.625 1.625 0 012.298 0l1.415-1.414a3.625 3.625 0 00-5.127 0l1.414 1.414zm2.298 0a1.625 1.625 0 010 2.298l1.415 1.415a3.625 3.625 0 000-5.127l-1.415 1.414zm3.27 4.154L7.39 16.873l-1.415 1.415 1.857 1.856 1.414-1.415zm9.724-11.136L7.83 18.729l1.414 1.415L20.382 9.007l-1.415-1.414zM17.11 7.15l1.857 1.856 1.414-1.414-1.856-1.857-1.415 1.415z"
						fill="#fff"
					/>
					<path
						d="M9.625 7.875L12.1 10.35"
						stroke="#fff"
						stroke-width="2"
						stroke-linecap="round"
						stroke-linejoin="round"
					/>
				</g>
				<defs>
					<clipPath id="clip0">
						<path fill="#fff" d="M0 0h21v21H0z" />
					</clipPath>
				</defs>
			</svg> -->
			<img
				src="@/assets/tickets-v2.svg"
				width="30"
				height="30"
				class="align-self-center bp-1024:display-none"
				alt=""
				style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);"
			/>
			<!-- #fff == filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);; -->
		</router-link>
		<button
			class="c-navigation__toggle bp-1024:display-none reset height-100 transition"
			:class="mobile_menu_open ? 'is-toggled' : undefined"
			@click.prevent="toggle_mobile_menu"
		>
			<svg class="hamburger height-100 width-100" viewBox="0 0 100 100">
				<path
					class="line top"
					d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"
				/>
				<path class="line middle" d="m 30,50 h 40" />
				<path
					class="line bottom"
					d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"
				/>
			</svg>
		</button>
	</div>
</template>

<script>
import { ChevronDownIcon, ChevronRightIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';
import Search from '@/components/Search';

export default {
	name: 'MainNavigationFull',

	components: {
		ChevronDownIcon,
		ChevronRightIcon,
		NavigationLink,
		Search,
	},

	props: {
		data: {
			required: true,
			type: Array,
			default: () => [],
		},
	},

	data: () => ({
		mobile_menu_open: false,
	}),

	methods: {
		get_gender(text) {
			if (text === 'Mens') {
				return 'M';
			} else if (text === 'Womens') {
				return 'W';
			} else {
				return '';
			}
		},
		open_mobile_menu() {
			this.mobile_menu_open = true;
		},

		close_mobile_menu() {
			this.mobile_menu_open = false;
		},

		toggle_mobile_menu() {
			this.mobile_menu_open = !this.mobile_menu_open;
		},

		open_menu_item(item) {
			item.is_open = true;
		},

		close_menu_item(item) {
			item.is_open = false;
		},

		toggle_menu_item(item) {
			this.data.forEach(_item => {
				if (_item.id != item.id && _item.is_open) {
					_item.is_open = false;
				}
			});

			item.is_open = !item.is_open;
		},

		close_dropdowns() {
			this.data.forEach(item => {
				if (item.is_open) {
					this.close_menu_item(item);
				}
			});
		},
	},

	watch: {
		$route() {
			this.close_mobile_menu();
			this.close_dropdowns();
		},
	},

	created() {
		this.close_mobile_menu();
		this.close_dropdowns();
	},
};
</script>

<style lang="scss" scoped>
.c-navigation {
	//for naming
	&__logo-img {
		height: 32px;
	}
	&__my-apps {
		flex: 0 0 60px;
		width: 60px;

		&:hover,
		&:focus {
			background: var(--color-text-on-primary);
			color: var(--color-primary);
		}
	}
	&__toggle {
		border-left: 1px solid var(--color-primary-hover);
		flex: 0 0 60px;
		outline: none;
		width: 60px;

		&:hover,
		&:focus {
			cursor: pointer;
		}

		.hamburger {
			transition: transform 400ms;
			.line {
				fill: none;
				transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
				stroke: var(--color-text-on-primary);
				stroke-width: 3;
				&.top {
					stroke-dasharray: 40 160;
				}
				&.middle {
					stroke-dasharray: 40 142;
					transform-origin: 50%;
					transition: transform 400ms;
				}
				&.bottom {
					stroke-dasharray: 40 85;
					transform-origin: 50%;
					transition: transform 400ms, stroke-dashoffset 400ms;
				}
			}
		}
		&:hover,
		&:focus,
		&.is-toggled {
			background: var(--color-default-theme-background);
			.hamburger {
				.line {
					stroke: var(--color-default-theme-brand-text);
				}
			}
		}
		&.is-toggled {
			.hamburger {
				transform: rotate(45deg);
				.line {
					&.top {
						stroke-dashoffset: -64px;
					}
					&.middle {
						transform: rotate(90deg);
					}
					&.bottom {
						stroke-dashoffset: -64px;
					}
				}
			}
		}
	}
	&__url[href] {
		&:hover,
		&:focus {
			cursor: pointer;
		}
	}
	&__tickets {
		border-left: 1px solid #09649b;
		flex: 0 0 60px;
		height: 100%;

		&:hover,
		&:focus {
			background: var(--color-text-on-primary);
			color: var(--color-primary);

			svg path {
				fill: var(--color-primary);
				stroke: var(--color-primary);
			}
		}

		&:hover img,
		&:focus img {
			filter: invert(28%) sepia(94%) saturate(1703%) hue-rotate(182deg) brightness(84%) contrast(92%) !important;
		}
	}
}

.c-navigation-mobile {
	.c-navigation {
		//for naming
		&__offcanvas {
			height: 0;
			overflow: hidden;

			&.is-open {
				height: calc(100vh - var(--header-height));
				overflow: auto;
			}
		}

		&__item--level-1 {
			border-bottom: 1px solid #09649b;

			&.is-open {
				.c-navigation__url--level-1 {
					svg {
						transform: rotate(-180deg);
					}
				}
			}
		}

		&__parent {
			&.is-open {
				.c-navigation__url--level-1 {
					background: var(--color-primary-hover);
				}
			}
		}

		&__level-2 {
			height: 0;
			overflow: hidden;

			&.is-open {
				height: auto;
				overflow: auto;
			}
		}

		&__item--level-2 {
			&:last-of-type {
				border-bottom: none;
			}
		}
	}
}

.c-navigation-desktop {
	.c-navigation {
		//for naming
		&__item--level-1 {
			&.is-open {
				.c-navigation__url--level-1 {
					svg {
						transform: rotate(-180deg);
					}
					&::after {
						transform: scaleY(1);
					}
				}

				.c-navigation__level-2 {
					opacity: 1;
					pointer-events: auto;
				}
			}
			&:not(.c-navigation__parent) {
				.c-navigation__url--level-1 {
					&:hover {
						svg {
							transform: translateX(3px);
						}
					}
				}
			}
		}
		&__level-2 {
			opacity: 0;
			pointer-events: none;

			&-inner {
				box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.08);
				max-height: calc(100vh - var(--full-header-height));
			}
		}
		&__column {
			flex: 0 1 33.3333%;
		}
		&__url--level-2 {
			font-size: 16px;
		}
	}
}

@media screen and (min-width: 1024px) {
	.c-navigation {
		//for naming
		&__tickets {
			background-color: #fff;
			border: 1px solid var(--color-text-on-primary);
			border-radius: 9999px;
			box-shadow: 0 0 0 #fff;
			color: var(--color-default-theme-brand-text);
			height: 32px;
			flex: 0 0 auto;

			&:hover,
			&:focus {
				box-shadow: 0 0 0 4px #fff;
			}
		}
	}
}
</style>

<style lang="scss">
.c-navigation--full {
	.c-navigation-desktop,
	.c-navigation-mobile {
		.c-navigation {
			//for naming
			&__url--level-3 {
				border: 1px solid var(--color-primary);
				border-radius: 50%;
				height: 32px;
				line-height: 30px;
				width: 32px;
				&:hover,
				&:focus {
					background: var(--color-text-on-primary);
					color: var(--color-primary) !important;
				}
			}
		}
	}
	.c-navigation-desktop {
		.c-navigation {
			//for naming
			&__url--level-1 {
				&::after {
					content: '';
					background: var(--color-text-on-primary);
					height: 2px;
					position: absolute;
					bottom: 0;
					left: 0;
					transform: scaleY(0);
					transform-origin: bottom center;
					transition: 0.25s;
					width: 100%;
				}

				&:hover,
				&:focus {
					&::after {
						transform: scaleY(1);
					}
				}
			}
			&__item--level-2:not(.c-navigation__separator) {
				&:hover,
				&:focus {
					.c-navigation__url {
						color: var(--color-text-on-primary);
					}
					.c-navigation__url--level-3 {
						border-color: var(--color-text-on-primary);
					}
				}
			}
		}
	}
	.c-navigation-mobile {
		.c-navigation {
			//for naming
			&__item--level-1 {
				&:hover,
				&:focus {
					.c-navigation__url--level-1 {
						background: #09649b;
					}
				}
			}
			&__item--level-2:not(.c-navigation__separator) {
				&:hover,
				&:focus {
					.c-navigation__url {
						color: var(--color-text-on-primary);
					}
					.c-navigation__url--level-3 {
						border-color: var(--color-text-on-primary);
					}
				}
			}
		}
	}
}
</style>

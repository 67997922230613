export default {
	methods: {
		setMetaSidearm(title, tags) {
			if (title) document.title = title + ' | ' + this.$root.site_title;

			Array.from(
				document.querySelectorAll('[data-vue-router-controlled]'),
			).map(el => el.parentNode.removeChild(el));

			if (tags && tags.length) {
				// find existing tags
				let ogTitle = document.querySelector("meta[name='og:title']");
				let twitterTitle = document.querySelector("meta[name='twitter:title']");
				let ogDesc = document.querySelector("meta[name='og:description']");
				let twitterDesc = document.querySelector("meta[name='twitter:description']");
				let ogImage = document.querySelector("meta[name='og:image']");
				let twitterImage = document.querySelector("meta[name='twitter:image']");
				let description = document.querySelector("meta[name='description']");

				// clean up old tags first
				if (ogTitle) document.head.removeChild(ogTitle);
				if (twitterTitle) document.head.removeChild(twitterTitle);
				if (ogDesc) document.head.removeChild(ogDesc);
				if (twitterDesc) document.head.removeChild(twitterDesc);
				if (ogImage) document.head.removeChild(ogImage);
				if (twitterImage) document.head.removeChild(twitterImage);
				if (description) document.head.removeChild(description);

				// append new tags
				tags.map(tagDef => {
					const tag = document.createElement('meta');

					Object.keys(tagDef).forEach(key => {
						tag.setAttribute(key, tagDef[key]);
					});
					tag.setAttribute('data-vue-controlled', '');

					return tag;
				}).forEach(tag => document.head.appendChild(tag));
			}
		},
	},
};

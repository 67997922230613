<template functional>
	<span>
		<!-- NCAATIX-118 -->
		<a
			v-if="props.isFevoTrackingButton"
			:class="props._class"
			:title="props._title"
			:aria-label="props._ariaLabel"
			:tabindex="props._tabIndex"
			:target="$options.getTarget(props)"
			:href="props.url"
			data-fevo-offer-id="2023-women-s-college-cup-87201f2"
			style=""
		>
			<slot></slot>
		</a>
		<a
			v-else-if="
				props.url.includes('http') || props.url.includes('https')
			"
			:class="props._class"
			:title="props._title"
			:aria-label="props._ariaLabel"
			:tabindex="props._tabIndex"
			:target="$options.getTarget(props)"
			:href="props.url"
			rel="noopener"
		>
			<slot></slot>
			<span>
				<component
					v-if="
						$options.getTarget(props) === '_blank' &&
							props._externalIcon
					"
					:is="injections.components.ExternalLinkIcon"
					class="margin-l-6 transition"
					size=".9x"
				/>
			</span>
		</a>
		<router-link
			v-else
			:class="props._class"
			:title="props._title"
			:aria-label="props._ariaLabel"
			:tabindex="props._tabIndex"
			:to="props.url"
		>
			<slot></slot>
		</router-link>
	</span>
</template>

<script>
import { is_external_link } from '../../helpers';
import { ExternalLinkIcon } from 'vue-feather-icons';

export default {
	name: 'NavigationLink',
	props: {
		url: {
			required: true,
			type: String,
			default: '',
		},
		_title: {
			type: String,
		},
		_class: {
			type: String,
		},
		_ariaLabel: {
			type: String,
		},
		_tabIndex: {
			type: String,
		},
		_target: {
			type: String,
		},
		_externalIcon: {
			type: Boolean,
			default: false,
		},
		isFevoTrackingButton: {
			type: Boolean,
			default: false,
		},
	},

	inject: {
		components: {
			default: { ExternalLinkIcon },
		},
	},

	getTarget({ _target, url }) {
		if (!_target || _target === '' || _target === undefined) {
			if (is_external_link(url)) {
				return '_blank';
			}
			return '_self';
		}
		return _target;
	},
};
</script>

<template>
	<nav v-if="data">
		<h2 class="accessible-hide">{{ sectionTitle }}</h2>
		<component :is="template" :data="data" />
	</nav>
</template>

<script>
import { get } from '@/helpers';
import MainNavigationFull from './templates/MainNavigationFull';
import MainNavigationMin from './templates/MainNavigationMin';
import MainNavigationFooter from './templates/MainNavigationFooter';

export default {
	name: 'MainNavigation',

	components: {
		MainNavigationFull,
		MainNavigationMin,
		MainNavigationFooter,
	},

	props: {
		dummy: {
			type: Boolean,
			default: false,
		},
		sectionTitle: {
			required: true,
			type: String,
		},
		template: {
			required: true,
			type: String,
		},
		type: {
			required: true,
			type: String,
		},
	},

	computed: {
		data() {
			if (this.template === 'MainNavigationFooter') {
				return this.$store.state.footer_nav_items;
			} else {
				return this.$store.state.main_nav_items;
			}
		},
	},
};
</script>

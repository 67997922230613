var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"c-navgation__container row max-width-grid bp-1024:padding-x-24 bp-1280:padding-x-0"},[_c('div',{staticClass:"c-navigation-mobile bp-1024:display-none width-100"},[_c('ul',{staticClass:"c-navigation__level-1 reset-list"},_vm._l((_vm.data),function(item,index){return _c('li',{key:index,staticClass:"c-navigation__item--level-1 border-b",class:[
					item.items && item.items.length
						? 'c-navigation__parent'
						: undefined,
					item.is_open ? 'is-open' : undefined ]},[(item.items.length || item.url === '')?_c('a',{staticClass:"c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16 font-weight-400 justify-content-space-between header-font padding-y-24 padding-x-32 text-decoration-none text-transform-uppercase transition",attrs:{"tabindex":"0","aria-label":item.title,"aria-haspopup":item.items && item.items.length ? 'true' : 'false',"aria-expanded":item.is_open ? 'true' : 'false',"title":item.title,"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();item.items && item.items.length
							? _vm.toggle_menu_item(item)
							: undefined},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }item.items && item.items.length
							? _vm.toggle_menu_item(item)
							: undefined}}},[_c('span',[_vm._v(_vm._s(item.title))]),_c('chevron-right-icon',{staticClass:"margin-l-auto transition",attrs:{"size":"1x"}})],1):_c('navigation-link',{staticClass:"c-navigation__url c-navigation__url--level-1 align-items-center display-flex font-size-16 font-weight-400 justify-content-space-between header-font padding-y-24 padding-x-32 text-decoration-none text-transform-uppercase transition",attrs:{"_aria-label":item.title,"_target":item.open_in_new_window ? '_blank' : '_self',"_title":item.title,"url":item.url ? item.url : '',"_external-icon":"","_tabIndex":"0"}},[_vm._v(" "+_vm._s(item.title)+" "),_c('chevron-right-icon',{staticClass:"margin-l-auto transition",attrs:{"size":"1x"}})],1),(item.items.length)?_c('div',{staticClass:"c-navigation__level-2",class:item.is_open ? 'is-open' : undefined},[_c('ul',{staticClass:"reset-list bg-color-black bp-1024:height-100"},_vm._l((item.items),function(_item,index){return _c('li',{key:index,staticClass:"c-navigation__item--level-2 border-b",class:[
								_item.separator
									? 'c-navigation__separator font-size-16 header-font'
									: undefined,
								_item.items && _item.items.length
									? 'display-flex align-items-center'
									: undefined ]},[(!_item.separator)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-2 display-block font-size-16 padding-y-12 padding-x-32 text-black text-decoration-none hover:text-decoration-underline","url":_item.url ? _item.url : '',"tabindex":item.is_open ? '0' : undefined,"_external-icon":""}},[_vm._v(_vm._s(_item.title))]):_c('span',{staticClass:"display-block padding-y-12 padding-x-32"},[_vm._v(_vm._s(_item.title))])],1)}),0)]):_vm._e()],1)}),0)]),_c('div',{staticClass:"c-navigation-desktop display-none bp-1024:display-block width-100"},[_c('ul',{staticClass:"c-navigation__level-1 display-flex reset-list width-100"},_vm._l((_vm.data),function(item,index){return _c('li',{key:index,staticClass:"c-navigation__item--level-1 padding-x-12",class:[
					index === 0 ? 'padding-l-0' : undefined,
					index === _vm.data.length - 1 ? 'padding-r-0' : undefined ]},[_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-1 font-size-18 font-weight-400 header-font text-transform-uppercase text-decoration-none","url":item.url != '' ? item.url : 'javascript:void(0)',"_external-icon":true}},[_vm._v(_vm._s(item.title))]),(item.items.length)?_c('ul',{staticClass:"c-navigation__level-2 margin-t-16 reset-list"},_vm._l((item.items),function(_item,index){return _c('li',{key:index,staticClass:"c-navigation__item--level-2 margin-b-8",class:_item.separator
								? 'c-navigation__separator header-font'
								: undefined},[(!_item.separator)?_c('navigation-link',{attrs:{"_class":"c-navigation__url c-navigation__url--level-2 align-items-center display-flex font-size-16 text-decoration-none hover:text-decoration-underline","_aria-label":_item.title,"url":_item.url ? _item.url : '',"_tab-index":"0","_target":_item.open_in_new_window
									? '_blank'
									: '_self',"_title":_item.title,"_external-icon":""}},[_c('chevron-right-icon',{staticClass:"margin-r-4 transition",attrs:{"size":"1x"}}),_c('span',[_vm._v(_vm._s(_item.title))])],1):_c('span',[_vm._v(_vm._s(_item.title))])],1)}),0):_vm._e()],1)}),0)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"be-ix-link-block"})])}]

export { render, staticRenderFns }